import { AnimatePresence } from 'framer-motion'
import * as Portal from '@radix-ui/react-portal'
import { useAppModule } from '../appContext.ts'
import { useSnapshot } from 'valtio/react'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import {
  Drawer,
  DrawerBar,
  DrawerClose,
  DrawerContent,
} from '../../shared/ui/drawer/drawer.tsx'
import { useState } from 'react'
import { Button } from '../../shared/ui/button/button.tsx'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../shared/urls.ts'
import { AppMessageKeys } from '../../shared/translations/messages.ts'

type ErrorKeys =
  | 'Token is invalid or expired'
  | 'Not found'
  | 'User already registered'
  | 'Email not found'
  | 'Invalid credentials'
  | 'Can not recognize word'
  | 'You must buy subscription.'
  | 'No active subscription'

const DEFAULT_BTN_CONFIG = {
  okBtn: true,
  supportBtn: false,
  profileBtn: false,
  loginBtn: false,
  textId: 'error.somethingWentWrong' as AppMessageKeys,
}

type ButtonConfig = typeof DEFAULT_BTN_CONFIG

export const ERRORS: Record<ErrorKeys, ButtonConfig> = {
  'Token is invalid or expired': {
    okBtn: true,
    supportBtn: true,
    profileBtn: false,
    loginBtn: false,
    textId: 'error.somethingWentWrong',
  },
  'Not found': {
    okBtn: false,
    supportBtn: true,
    profileBtn: true,
    loginBtn: false,
    textId: 'error.notFound',
  },
  'User already registered': {
    okBtn: true,
    supportBtn: false,
    profileBtn: false,
    loginBtn: true,
    textId: 'error.userRegistered',
  },
  'Email not found': {
    okBtn: true,
    supportBtn: false,
    profileBtn: false,
    loginBtn: false,
    textId: 'error.emailNotFound',
  },
  'Invalid credentials': {
    okBtn: true,
    supportBtn: false,
    profileBtn: false,
    loginBtn: false,
    textId: 'error.invalidCredentials',
  },
  'Can not recognize word': {
    okBtn: true,
    supportBtn: false,
    profileBtn: false,
    loginBtn: false,
    textId: 'error.canNotRecognizeWord',
  },
  'You must buy subscription.': {
    okBtn: false,
    supportBtn: false,
    profileBtn: true,
    loginBtn: false,
    textId: 'error.mustBuySubscription',
  },
  'No active subscription': {
    okBtn: false,
    supportBtn: false,
    profileBtn: true,
    loginBtn: false,
    textId: 'error.noSubscriotion',
  },
}

export function Toasts() {
  const toastStore = useAppModule().toastStore
  const state = useSnapshot(toastStore.state)
  return (
    <Portal.Root asChild>
      <AnimatePresence initial={false}>
        {state.errors.length > 0 && (
          <>
            {state.errors.map((error, i) => (
              <ToastDrawer
                key={`${error}-${i}`}
                handler={() =>
                  setTimeout(() => {
                    toastStore.hideError(i)
                  }, 200)
                }
                error={error}
              />
            ))}
          </>
        )}
      </AnimatePresence>
    </Portal.Root>
  )
}

const ToastDrawer = ({
  handler,
  error,
}: {
  handler: () => void
  error: string
}) => {
  const [open, setOpen] = useState<boolean>(true)
  const navigate = useNavigate()

  const handleClose = () => {
    setOpen(false)
    handler()
  }
  const isExceptionError = Object.keys(ERRORS).includes(error)
  const errorConfig = isExceptionError
    ? ERRORS[error as ErrorKeys]
    : DEFAULT_BTN_CONFIG

  return (
    <Drawer open={open}>
      <DrawerContent
        direction="bottom"
        className="mx-auto mt-24 flex h-auto max-w-800 flex-col rounded-t-32 bg-white"
      >
        <DrawerBar />

        <div className="relative flex flex-col py-32">
          <div className="flex items-center justify-between px-28">
            <div className="text-22 font-bold">
              <FormattedMessage id="oops" />
            </div>
          </div>
          <div className="mt-14 w-full px-28 font-nunito-7-semicondensed text-14">
            <FormattedMessage id={errorConfig.textId} />
          </div>
          <div className="mt-24 flex flex-col gap-4 px-16">
            {errorConfig.okBtn && (
              <Button
                rounded="full"
                bg="blue-gradient-shadow-inset"
                size="extralarge"
                onClick={handleClose}
              >
                <FormattedMessage id="ok" />
              </Button>
            )}
            {errorConfig.profileBtn && (
              <Button
                rounded="full"
                bg="blue-gradient-shadow-inset"
                size="extralarge"
                onClick={() => {
                  navigate(urls.profile())
                  handleClose()
                }}
              >
                <FormattedMessage id="goProfile" />
              </Button>
            )}
            {errorConfig.loginBtn && (
              <Button
                rounded="full"
                bg="transparent"
                size="extralarge"
                onClick={() => {
                  handleClose()
                  setTimeout(() => {
                    navigate(urls.login)
                  }, 200)
                }}
              >
                <FormattedMessage id="login" />
              </Button>
            )}
            {errorConfig.supportBtn && (
              <Button
                rounded="full"
                bg="transparent"
                size="extralarge"
                onClick={() => {
                  navigate(urls.profile('support'))
                  handleClose()
                }}
              >
                <FormattedMessage id="profile.support" />
              </Button>
            )}
          </div>
          <DrawerClose asChild>
            <div
              className="absolute right-28 top-28 flex size-32 items-center justify-center rounded-full bg-gray"
              onClick={handleClose}
            >
              <Icon iconName="close" className="text-black" />
            </div>
          </DrawerClose>
        </div>
      </DrawerContent>
    </Drawer>
  )
}
