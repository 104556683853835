import { useAppModule } from '../appContext.ts'
import { useNavigate } from 'react-router-dom'
import { cn, getFormObject, useMount } from '../../shared/lib/utils.ts'
import { Toasts } from '../toasts/toasts.tsx'
import { urls } from '../../shared/urls.ts'
import { useSnapshot } from 'valtio/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from '../../shared/ui/button/button.tsx'
import { Spinner } from '../../shared/ui/spinner.tsx'
import { TextInput } from '../../shared/ui/textInput/textInput.tsx'
import { Tabs } from '../../shared/ui/tabs/tabs.tsx'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import React, { useEffect, useState } from 'react'
import { LangSwitcher } from '../appHeader/appHeader.tsx'

export const termsUrl = 'https://img.edman.ai/pdf/terms.pdf'
export const recurrentTermsUrl = 'https://img.edman.ai/pdf/recurrent.pdf'
export const privacyPolicyUrl = 'https://img.edman.ai/pdf/privacy_policy.pdf'
export const savingCredentialsUrl =
  'https://img.edman.ai/pdf/agreement_saving_credentials.pdf'
export function Login(props: { registration: boolean }) {
  const authStore = useAppModule().authStore
  useMount(() => {
    void authStore.saveMarkData()
    authStore.trackEvent('registration_page_load')
  })
  const navigate = useNavigate()
  return (
    <div className="size-full min-h-dvh px-16">
      <div
        data-testid="login-page"
        className="relative mx-auto flex min-h-dvh max-w-400 flex-col pt-[15vh] msm:pt-4"
      >
        <LangSwitcher darkBorder={true} top className="sm:pt-16" />
        <img className="mx-auto mt-12" src="/images/logo.svg" alt="logo" />
        <h1 className="mb-52 mt-24 w-full text-center text-14 text-gray4">
          <FormattedMessage id="personalTutor" />
        </h1>

        <Tabs.Root defaultValue={props.registration ? 'registration' : 'login'}>
          {/* <Tabs.List>
            <Tabs.Trigger value="login">
              <Link to={urls.login}>
                <FormattedMessage id="login" />
              </Link>
            </Tabs.Trigger>
            <Tabs.Trigger value="registration">
              <Link to={urls.registration}>
                <FormattedMessage id="registration" />
              </Link>
            </Tabs.Trigger>
          </Tabs.List> */}

          <Tabs.Content value="login">
            <RegLoginForm
              onSubmit={(data) => {
                void authStore.login(data.login, data.password).then(() => {
                  navigate('/')
                })
              }}
              login={true}
              registrationWithoutPassword={false}
              btnText={<FormattedMessage id="login" />}
            />
          </Tabs.Content>

          {/* <Tabs.Content value="registration">
            <RegLoginForm
              onSubmit={(data) => {
                void authStore
                  .registration(data.login, data.password)
                  .then((user) => {
                    if (user.account_confirmed) {
                      navigate(urls.onboarding())
                    } else {
                      navigate(urls.confirm)
                    }
                  })
              }}
              login={false}
              btnText={<FormattedMessage id="registration" />}
            />
          </Tabs.Content> */}
        </Tabs.Root>
        <div className="text-center">
          <p className="text-18 font-bold">
            <FormattedMessage id="forgotPassword" />{' '}
            <span
              className="cursor-pointer text-blue4 underline underline-offset-4"
              onClick={() => {
                navigate(urls.passwordRecovery)
              }}
            >
              <FormattedMessage id="clickHere" />
            </span>
          </p>
          <DividerWithText />
          <Button
            size="extralarge"
            bg="black-gradient"
            rounded="full"
            className="mt-16 w-full cursor-pointer text-center"
            onClick={() => {
              navigate(urls.onboarding())
            }}
          >
            <FormattedMessage id="register" />
          </Button>
        </div>
        <div className="mb-16 mt-auto">
          <FormattedMessage
            id="sendQuestions"
            values={{
              contactSupport: (
                <span
                  className="text-blue4"
                  onClick={() => {
                    navigate(urls.contacts)
                  }}
                >
                  <FormattedMessage id="contactSupport" />
                </span>
              ),
            }}
          />
        </div>
        {/* <ContactsBlock full /> */}

        <Toasts />
      </div>
    </div>
  )
}

interface RegistrationProps {
  btnText: React.ReactNode
  login: boolean
  registrationWithoutPassword?: boolean
  disableButton?: boolean
  recurrentText?: React.ReactNode
  onSubmit: (data: { login: string; password: string }) => void
}

export function RegLoginForm(props: RegistrationProps) {
  const [isPassVisible, setIsPassVisible] = useState<boolean>(false)
  const [isEyeVisible, setIsEyeVisible] = useState<boolean>(false)
  const authStore = useAppModule().authStore
  const intl = useIntl()
  const state = useSnapshot(authStore.state)
  const prefix = props.login ? 'login-' : 'reg-'

  useEffect(() => {
    authStore.setRecurrent(!props.registrationWithoutPassword)
    authStore.setAutopay(!props.registrationWithoutPassword)
  }, [authStore, props.registrationWithoutPassword])

  const emailInputId = props.registrationWithoutPassword
    ? 'subscriptions-email-input'
    : undefined

  const buttonId = props.registrationWithoutPassword
    ? 'subscriptions-reg-button'
    : undefined

  const togglePassVisible = () => {
    setIsPassVisible((prev) => !prev)
  }

  const handleChangePass = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEyeVisible(!!e.target.value)
  }
  return (
    <form
      className="flex size-full flex-col items-center p-16"
      onSubmit={(e) => {
        e.preventDefault()
        const data = getFormObject(e.target as HTMLFormElement) as {
          login: string
          password: string
        }
        props.onSubmit(data)
      }}
    >
      <div className="flex w-full flex-col gap-4">
        {/* {!props.registrationWithoutPassword && <span>Email</span>} */}

        <TextInput
          type="email"
          id={emailInputId}
          placeholder="E-mail"
          name="login"
          size="extralarge"
          required={true}
          className="w-full text-default"
        />
      </div>
      {props.registrationWithoutPassword && (
        <div className="px-16 pt-6 font-nunito-7-semicondensed text-12 font-semibold text-gray3">
          <FormattedMessage id="WeSendPassword" />
        </div>
      )}

      {!props.registrationWithoutPassword && (
        <div className="mt-16 flex w-full  flex-col gap-4">
          {/* <span>
            <FormattedMessage id="password" />
          </span> */}
          <div className="relative w-full">
            <TextInput
              type={isPassVisible ? 'text' : 'password'}
              minLength={5}
              name="password"
              data-testid={prefix + 'password'}
              required={true}
              size="extralarge"
              className="w-full text-default"
              onChange={handleChangePass}
              placeholder={intl.formatMessage({ id: 'password' })}
            />
            {isEyeVisible && (
              <button
                type="button"
                onClick={togglePassVisible}
                className={cn(
                  'absolute right-14 top-1/2 -translate-y-1/2',
                  isPassVisible ? 'text-black' : 'text-message-button',
                )}
                aria-label={isPassVisible ? 'Скрыть пароль' : 'Показать пароль'}
              >
                <Icon iconName={isPassVisible ? 'eyeOpen' : 'eyeClose'} />
              </button>
            )}
          </div>
        </div>
      )}

      {props.registrationWithoutPassword && (
        <div className="mt-18">
          {/* <FormCheckbox
              checked={state.recurrent}
              onChange={(e) => {
                authStore.setRecurrent(e.target.checked)
              }}
            > */}
          {props.recurrentText}
          {/* </FormCheckbox> */}

          {/* <FormCheckbox
              checked={state.autoPay}
              onChange={(e) => {
                authStore.setAutopay(e.target.checked)
              }}
            >
              <div>
                <FormattedMessage
                  id="recurrentTerms"
                  values={{
                    btnText: props.btnText,
                    termsLink: (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="underline"
                        href={recurrentTermsUrl}
                      >
                        <FormattedMessage id="recurrentTermsLinkName" />
                      </a>
                    ),
                  }}
                />
              </div>
            </FormCheckbox> */}
        </div>
      )}

      {/* {!props.login && (
        <FormCheckbox
          checked={state.termsAgree}
          onChange={(e) => {
            authStore.setTermsAgree(e.target.checked)
          }}
        >
          <div>
            <FormattedMessage
              id={props.registrationWithoutPassword ? 'terms' : 'termsLogin'}
              values={{
                btnText: props.btnText,
                termsLink: (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                    href={termsUrl}
                  >
                    <FormattedMessage id="termsLinkName" />
                  </a>
                ),
                recurrentLink: (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                    href={recurrentTermsUrl}
                  >
                    <FormattedMessage id="recurrentTermsLinkName" />
                  </a>
                ),
              }}
            />
          </div>
        </FormCheckbox>
      )} */}

      <Button
        bg="blue-gradient"
        id={buttonId}
        className={cn(
          'w-full justify-center',
          props.registrationWithoutPassword ? 'msm:mt-auto sm:mt-32' : 'mt-24',
        )}
        size="large"
        rounded="full"
        disabled={state.loading || props.disableButton}
        data-testid={prefix + 'btn'}
        type="submit"
      >
        {state.loading ? (
          <Spinner size="medium" className="text-gray-dark" />
        ) : (
          props.btnText
        )}
        {props.registrationWithoutPassword && (
          <div className="absolute inset-y-0 right-12 flex items-center">
            <Icon iconName="arrowRight" />
          </div>
        )}
      </Button>
    </form>
  )
}
// need to deprecate
// function FormCheckbox(props: {
//   checked: boolean
//   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
//   children: React.ReactNode
// }) {
//   return (
//     <label className="flex items-start gap-8 px-8 pt-8 text-14 leading-tight">
//       <input
//         type="checkbox"
//         className="mr-4 mt-2 inline-block shrink-0 scale-125 accent-blue4"
//         checked={props.checked}
//         onChange={props.onChange}
//       />{' '}
//       {props.children}
//     </label>
//   )
// }

const DividerWithText = () => {
  return (
    <div className="mt-20 flex items-center justify-center gap-10">
      <div className="h-1 grow bg-black-20"></div>
      <span className="mx-8 text-black-20">
        <FormattedMessage id="or" />
      </span>
      <div className="h-1 grow bg-black-20"></div>
    </div>
  )
}

export default DividerWithText
