import { proxy } from 'valtio'
import { AuthStore } from '../auth/authStore.ts'
import {
  ChatApi,
  CloudPaymentsPaymentData,
  SubscriptionPlan,
} from '../../shared/api/chatApi.ts'
import { ErrorNotifier } from '../../shared/api/httpClient.ts'
import { setLoading } from '../../shared/lib/storeUtils.ts'
import { createStoreContext, useStoreContext } from '../../shared/lib/hooks.ts'
import { AppStore } from '../whatisnew/appStore.ts'
import { isMobileByUserAgent } from '../../shared/lib/utils.ts'

export type Country = 'ru' | 'kz'

export interface CloudPaymentsWidget {
  auth(
    paymentData: CloudPaymentsPaymentData,
    onSuccess: (data: string) => void,
  ): void
}

export declare const cp: {
  CloudPayments: new () => CloudPaymentsWidget
  PaymentBlocks: PaymentBlocks
}

interface PaymentBlocksOptions {
  appearance: {
    colors: {
      primaryButtonColor: string
      primaryButtonTextColor: string
      primaryHoverButtonColor: string
      primaryButtonHoverTextColor: string
      activeInputColor: string
      inputBackground: string
      inputColor: string
      inputBorderColor: string
      errorColor: string
      applePaySupport: boolean
      googlePaySupport: boolean
      yandexPaySupport: boolean
      tinkoffPaySupport: boolean
      sbpSupport: boolean
    }
    borders: {
      radius: string
    }
  }
  components: {
    paymentButton: {
      text: string
      fontSize: string
    }
    paymentForm: {
      labelFontSize: string
      activeLabelFontSize: string
      fontSize: string
    }
  }
}

type PaymentBlocks = new (
  paymentData: CloudPaymentsPaymentData,
  options: PaymentBlocksOptions,
) => {
  mount: (element: HTMLElement) => void
  on: (eventName: string, callback: (result: React.ReactNode) => void) => void
  destroy: () => void
}

interface State {
  plan: SubscriptionPlan
  trial: boolean
  login: string
  loading: boolean
  showForm: boolean
  country: Country
  recurrent: boolean
  showReturnMessage: boolean
  successUrl: string
}

export class BuyStore {
  state = proxy<State>({
    plan: '12M',
    recurrent: true,
    trial: false,
    login: '',
    showForm: false,
    loading: false,
    country: 'ru',
    showReturnMessage: false,
    successUrl: '',
  })

  setCountry(country: Country) {
    this.state.country = country
  }

  get canBuy() {
    return !this.authStore.state.isAnon && this.authStore.isLogin()
  }
  constructor(
    private authStore: AuthStore,
    private appStore: AppStore,
    private chatApi: ChatApi,
    private errorNotifier: ErrorNotifier,
  ) {
    this.state.showReturnMessage = this.canBuy
  }

  setShowForm(showForm: boolean) {
    this.state.showForm = showForm
  }

  async registrationWithEmail(email: string) {
    await this.authStore.registration(email, '')
    await this.setUI()
  }

  setRecurrent(recurrent: boolean) {
    this.state.recurrent = recurrent
  }

  setPlan(plan: SubscriptionPlan) {
    this.state.plan = plan
  }

  getPlan() {
    return this.state.plan
  }

  setTrial(value: boolean) {
    this.state.trial = value
  }

  getTrial() {
    return this.state.trial
  }

  setLogin(login: string) {
    this.state.login = login
  }

  getLogin() {
    return this.state.login
  }

  setSuccessURL(url: string) {
    this.state.successUrl = url
  }

  getSuccessURL() {
    return this.state.successUrl
  }

  async setUI() {
    if (this.state.country == 'kz') {
      return
    }

    if (!this.canBuy) {
      this.state.showForm = true
      return
    }

    this.authStore.trackMetrika('paing_link_sent')
    this.authStore.trackMetrika('demo2_payment_link')

    await this.chatApi.patchAccount({
      ui_lang: this.appStore.state.language,
      ui_device: isMobileByUserAgent() ? 'mobile' : 'desktop',
    })
  }

  // async getSub() {
  //   return await setLoading(this.state, () =>
  //     this.getTrial()
  //       ? this.chatApi.subscribe_trial(this.state.plan)
  //       : this.chatApi.subscribe(this.state.plan),
  //   )
  // }

  async getSub() {
    return await setLoading(this.state, () =>
      this.chatApi.subscribe_trial(this.state.plan),
    )
  }

  cloudPaymentsConstructor(paymentData: CloudPaymentsPaymentData) {
    const paymentOptions: PaymentBlocksOptions = {
      appearance: {
        colors: {
          primaryButtonColor: '#2e71fc',
          primaryButtonTextColor: '#ffffff',
          primaryHoverButtonColor: '#2e71fc',
          primaryButtonHoverTextColor: '#ffffff',
          activeInputColor: '#0b1e46',
          inputBackground: '#ffffff',
          inputColor: '#8c949f',
          inputBorderColor: '#e2e8ef',
          errorColor: '#eb5757',
          applePaySupport: true,
          googlePaySupport: true,
          yandexPaySupport: true,
          tinkoffPaySupport: true,
          sbpSupport: true,
        },
        borders: {
          radius: '8px',
        },
      },
      components: {
        paymentButton: {
          text: 'Оплатить',
          fontSize: '16px',
        },
        paymentForm: {
          labelFontSize: '16px',
          activeLabelFontSize: '12px',
          fontSize: '16px',
        },
      },
    }
    return new cp.PaymentBlocks(paymentData, paymentOptions)
  }

  async buyConstructor() {
    const result = await this.getSub()

    if (result.status == 'success') {
      if (Object.keys(result.payment_data).length === 0) {
        // this.authStore.setUserEmployee(true)
        window.location.href = result.success_url
      }

      this.setSuccessURL(result.success_url)
      const paymentData = result.payment_data
      return this.cloudPaymentsConstructor(paymentData)
    }
  }
}
export const BuyStoreContext = createStoreContext<BuyStore>()

export function useBuyStore() {
  return useStoreContext(BuyStoreContext)
}
