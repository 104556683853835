import { Outlet } from 'react-router-dom'
import { Toasts } from '../features/toasts/toasts.tsx'
import { Menu } from '../features/rightmenu/menu.tsx'
import { useIsMobile } from '../shared/lib/hooks.ts'
import { cn, useMount } from '../shared/lib/utils.ts'
import { Navbar } from '../features/navbar/navbar.tsx'
import { MainContainer } from '../shared/ui/card/card.tsx'
import { useAppModule } from '../features/appContext.ts'
import { SubscribeButton } from '../features/subscribeButton/subscribeButton.tsx'
import { Student } from 'src/shared/api/chatApi.ts'
import { useState } from 'react'

export function Layout(props: {
  withMenu: boolean
  withNavbar: boolean
  fullWidth?: boolean
  scroll?: boolean
  white?: boolean
  customContainer?: boolean
  mainContainer?: boolean
}) {
  const isMobile = useIsMobile()
  const authStore = useAppModule().authStore

  const [isLoading, setIsLoading] = useState(false)

  useMount(() => {
    const studentActive = async () => {
      if (authStore.isLogin()) {
        const student: Student = await authStore.getStudent()
        if (!student.active && props.mainContainer) {
          authStore.logoutAndRefresh()
          return
        }
      }
      setIsLoading(true)
    }
    void studentActive()
  })

  return (
    <>
      {isLoading && (
        <div
          className={cn(
            'w-full [scrollbar-width:thin]',
            props.white ? 'bg-white xl:bg-bglight' : 'bg-light',
          )}
        >
          <div className="relative flex min-h-dvh gap-40">
            {props.withNavbar && (isMobile ? <Navbar /> : <Menu />)}
            {props.withMenu && !isMobile && <Menu />}
            <MainContainer
              enable={!props.customContainer && props.withNavbar && !isMobile}
            >
              {!authStore.state.isActiveSubscribe && props.mainContainer && (
                <SubscribeButton />
              )}
              <Outlet />
            </MainContainer>
          </div>
          <Toasts />
        </div>
      )}
    </>
  )
}
